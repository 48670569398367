// App.js
import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import EthereumContext from './EthereumContext';
import ComponentA from './ComponentA';
import ComponentB from './ComponentB';
import ComponentC from './ComponentC';
//import { detectEthereumProvider } from '@metamask/detect-provider';

function App() {
  const [isConnected, setIsConnected] = useState(false); 
  const [signer, setSigner] = useState(null);

  /*
  useEffect(() => {
    const init = async () => {
      try {
        const provider = await detectEthereumProvider();
  
        if (provider) {
          const _provider = new ethers.providers.Web3Provider(provider);
          const _signer = _provider.getSigner();
          setSigner(_signer);
        } else {
          console.error('Please install MetaMask');
        }
      } catch (error) {
        console.error('Failed to initialize MetaMask:', error);
      }
    };
  
    init();
  }, []);
  
  const connectMetaMask = async () => {
    if (!signer) {
      console.error('Signer is not available');
      return false;
    }
  
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const _account = await signer.getAddress();
      console.log('Connected account:', _account);
      return true;
    } catch (error) {
      console.error('Failed to connect to MetaMask:', error);
      return false;
    }
  };
  */
  
  useEffect(() => {
    const init = async () => {
      if (window.ethereum) {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const _signer = await provider.getSigner();
        setSigner(_signer);
      } else {
        console.error('Please install MetaMask');
      }
    };

    init();
  }, []);

  const connectMetaMask = async () => {
    if (!signer) {
      console.error('Signer is not available');
      return false;
    }

    try {
      if (window.ethereum.isMetaMask) {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const _account = await signer.getAddress();
        console.log('Connected account:', _account);
        return true;
      } else {
        const mobileAppUrl = 'metamask://open';
        window.location.href = mobileAppUrl;
        return false;
      }
    } catch (error) {
      console.error('Failed to connect to MetaMask:', error);
      return false;
    }
  };
  /*  
    try {
      if (window.ethereum.isMetaMask) {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const _account = await signer.getAddress();
        console.log('Connected account:', _account);
        return true;
      } else if (window.ethereum.isStatus) {
        window.location.href = 'status-im://wallet';
        return false;
      } else if (window.ethereum.isTrust) {
        window.location.href = 'trust://browser_enable';
        return false;
      } else {
        console.error('Unsupported mobile wallet');
        return false;
      }
    } catch (error) {
      console.error('Failed to connect to MetaMask:', error);
      return false;
    }
  };

    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const _account = await signer.getAddress();
      console.log('Connected account:', _account);
      return true;
    } catch (error) {
      console.error('Failed to connect to MetaMask:', error);
      return false;
    }
  };
*/

  return (
    <EthereumContext.Provider value={{ isConnected, setIsConnected, signer, connectMetaMask }}>
    <div className='App'> 
        <ComponentA />
        <ComponentB />
        <ComponentC />
    </div>
    </EthereumContext.Provider>
  );
}

export default App;